<script setup>
import { transl } from "@/composables/useTranslation";
const props = defineProps(["modelValue", "todaysModel"]);
const emit = defineEmits(["update:modelValue"]);

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});

function handleOpen() {
  dialog.value = true;
}

function close() {
  dialog.value = false;
}

function deletePlanProgress() {
  useEvent("plans.ondeleteplan");
  close();
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    :options="{
      style: {
        borderRadius: '8px',
      },
    }"
  >
    <div class="dialog-header">
      <!-- <v-btn icon class="icon-size" @click="close()">
        <img src="/icons/close-icon.svg" alt=""/></v-btn> -->
      <!-- <div class="icon-size"></div> -->
    </div>
    <div class="dialog-content">
      <div class="alert">
        <div class="description mb-4">
          {{ transl("Do you really want to delete your reading progress") }}
        </div>
        <div class="alert-buttons">
          <div class="mini-button-filled-grin" @click="deletePlanProgress()">
            {{ transl("Yes") }}
          </div>
          <div class="mini-button-filled-red" @click="close()">{{ transl("No") }}</div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<style scoped>
/* div :deep() .content {
  border-radius: 20px !important;
} */
/* div :deep(.content-slot) {
  padding: 10px;
} */
.icon-size img {
  margin-left: auto;
  margin-right: 10px;
}
.nav-header {
  min-height: var(--header-height);
  height: var(--header-height);
  background-color: var(--root-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--root-dark);
  font-weight: bold;
  font-size: 21px;
  /* position: sticky; */
  top: 0;
  z-index: 1;
  position: sticky;
  justify-content: flex-end;
}
.nav-title {
  display: flex;
  justify-content: center;
}

.icon-size {
  min-width: 40px;
  min-height: 40px;
  height: 20px;
  padding-right: 5px;
}
.icon-size img {
  width: 18px;
  filter: brightness(0) saturate(100%);
}

.alert {
  width: 255px;
  padding: 35px 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
}
.alert-buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.alert .description {
  font-size: 14px;
  color: #292937;
  text-align: center;
}
.mini-button-filled-grin {
  box-shadow: 0 7px 12px 0 rgba(0, 191, 164, 0.25);
  background: #00bfa4;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 23px;
  text-align: center;
}
.mini-button-filled-grin:hover {
  opacity: 0.8;
}
.mini-button-filled-red {
  box-shadow: 0 7px 12px 0 rgba(217, 15, 27, 0.25);
  background: #d90f1b;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 23px;
  text-align: center;
}
.mini-button-filled-red:hover {
  opacity: 0.8;
}
</style>
